<template>
  <section>
    <div class='page-table'>
      <el-table
        ref='multipleTable'
        height='400'
        :data='getCurrentData'
        tooltip-effect='dark'
      >
        <el-table-column
          type='index'
          width='50'>
        </el-table-column>
        <el-table-column label='产品'>
          <template slot-scope='scope'>
            <div class='xa-cell'>
              <div
                @click='onPriview(scope.row.first_pic)'
                class='img-wrap'
                :style="{
                    backgroundImage: 'url(' + scope.row.first_pic + ')',
                  }"
              ></div>
              <div>
                <div>{{ scope.row.title }}</div>
                <div class='xa-txt-red'>￥{{ scope.row.price }}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop='category_name' label='分类' width='200' />
        <el-table-column label='操作' width='100'>
          <template slot-scope='scope'>
            <el-button @click='onDelProduct(scope.row)' type='text' size='small'
            >删除
            </el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class='page-pagination xa-cell'>
      <div class='xa-flex'></div>
      <el-pagination
        @size-change='handleSizeChange'
        @current-change='handleCurrentChange'
        :current-page='query.pid'
        :page-sizes='[10, 20, 50, 100, 200, 500]'
        :page-size='query.psize'
        layout='total, sizes, prev, pager, next'
        :total='tableData.length'>
      </el-pagination>
    </div>
    <div class='page-btns xa-cell'>
      <el-button
        type='primary'
        size='small'
        @click='onSubimt'
      >确定
      </el-button>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SelectedProduct',
  props: {
    tableData: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      query: {
        pid: 0,
        psize: 10,
        keyword: '',
        selectedOptions: []
      }
    }
  },
  computed: {
    // 选取当前显示表格数据
    getCurrentData: function() {
      const { pid, psize } = this.query
      return this.tableData.slice(
        pid * psize,
        (pid + 1) * psize
      )
    }
  },
  watch: {
    tableData() {
      const { pid, psize } = this.query
      if (this.tableData.length / psize <= pid) {
        this.query.pid = Math.max(0, pid - 1)
      }
    }
  },
  methods: {
    onSubimt() {
      // 传递数据到上一层并关闭窗口
      this.$emit('changeCheckedProduct',this.tableData)
    },
    onPriview(img) {
      this.$preViewImg({ isShow: true, imgSrc: img, imgList: [img] })
    },

    handleSizeChange(val) {
      this.query.psize = val
    },

    handleCurrentChange(val) {
      this.query.pid = val - 1
    },

    onDelProduct(product) {
      const index = this.tableData.indexOf(product)
      this.tableData.splice(index, 1)
    }
  }
}
</script>

<style scoped lang='scss'>
.img-wrap {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border: 1px dotted #ccc;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-right: 4px;
  cursor: pointer;
}

.page-pagination {
  padding: 8px 0;
}

.page-btns {
  border-top: 1px solid #e4e4e4;
  padding: 8px 0;
  justify-content: flex-end;
}
</style>
